import React, { Fragment, Component } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

// Need to set these for the cookies
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';

import axios from 'axios'; /* *** */
import { Buffer } from 'buffer';

// @ts-ignore
window.Buffer = Buffer;

class Login extends Component { 

    // Add this line of code to add the cookies handler to your class props
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };


    //api funktion
    constructor(props) {
        super(props);

        // use axios for api call 
        this.axios = axios;

        // class component state
        this.state = {
            email: "",
            password: "",
            isAuthenticating: false,
            errorMessage: ""
        }
    }

    //Denna funktion kollar om email och lösenordet är tomt 
    isNotEmptyFields = () => {
        return this.state.email && this.state.password ? true : false
    }

    login = () => {
        if (!this.isNotEmptyFields()) return;

        this.setState(prevState => {
            return Object.assign({}, prevState, {
                isAuthenticating: true,
            })
        }, () => {
            this.loginProcess();            
        });
    }


    //hanterar inloggningsprocessen
    loginProcess = () => {
        if (this.state.isAuthenticating) {

            // Assign the credentials to the basic OAuth
            const username = this.state.email;
            const password = this.state.password;

            // Convert to base64 (vi krypterar våra inloggningsuppgifter till base64 så att de blir svårare att hacka sig in vid transporten till och från servern/databasen)
            const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
            const url = 'http://localhost:3005/authenticate';

            // Skickar de base64 kodade användarnamnet/lösenordet till auth.js under routes i express-api filen
            this.axios.post(url, {}, {
                headers : {
                    'Authorization': `Basic ${token}`
                }
            })
            .then((response) => { //när vi får svar från servern....

                // Set the user cookie here
                this.setState(prevState => {
                    return Object.assign({}, prevState, { //  ......gör vi knappen klickbar igen 
                        isAuthenticating: false //
                    });
                }, () => {

                    if (response.data.id) { //om authentiseringen gick igenom ger vi usern en kaka genom setUserCookie funktionen
                        this.setUserCookie(response.data.id);
                        window.location.href = '/adminpanel';
                    } else { // If there is not id, write error message
                        this.setState(prevState => {
                            return Object.assign({}, prevState, {
                                errorMessage: response.data.message
                            });
                        });
                    }
                });

            })
            .catch(() => { //om något problem uppstår, sätt ändå authenticating till false så man kan klicka på knappen igen
                this.setState(prevState => {
                    return Object.assign({}, prevState, {
                        isAuthenticating: false
                    })
                });
            });
        }
    }    
    
    setUserCookie = (id) => {
        this.props.cookies.set('userId', id, {
            path: '/',
            maxAge: process.env.REACT_APP_ENV_COOKIES_MAX_AGE //Cookie expiration skrivs direkt här, youtube rekommenderar .env fil, varför? https://youtu.be/4JNbKumPMHI?t=1364 maxAge: process.env.REACT_APP_ENV_COOKIES_MAX_AGE
        });
    }

    // We need this to handle the onChange of our input boxes
    handleChange = (event) => {
        let { name, value } = event.target;

        this.setState(prevState => {
            return Object.assign({}, prevState, {
                [name] : value
            });
        });
    }    

    render() {
        return (
            <Fragment>
                <Helmet>
                    <title>Login Page</title>
                </Helmet>
                <Container> {/* Dessa componenter är från react-bootstrap */}
                    <Row>
                        <Col>
                            <h2>Login { process.env.REACT_APP_ENV_COOKIES_MAX_AGE} </h2> {/* Inloggningsformulär */}
                            {this.state.errorMessage ? <p style={{color : "red"}}>{this.state.errorMessage}</p>:""}
                            <Form>
                                <Form.Group> <br></br>
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                        type="email"
                                        placeholder="Enter email address..."
                                        name="email"
                                        value={this.state.email}
                                        onChange={this.handleChange}
                                    />
                                </Form.Group>
                                <Form.Group><br></br>
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control
                                        type="password"
                                        placeholder="Enter password..."
                                        name="password"
                                        value={this.state.password}
                                        onChange={this.handleChange}
                                    />
                                </Form.Group> <br></br>
                                <Button disabled={this.state.isAuthenticating ? true : false} variant="primary" type="button" onClick={this.login} >  
                                    Login {/* Efter att ha klickat på login knappen blir den disabled för att undvika att spammklicka vår api, därav isAuthenticating variabeln */}
                                </Button>
                                <br/>
                                <Button className="float-lg-right" variant="success" type="button" href="/">
                                    Home page
                                </Button> 
                            </Form>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <br></br><h2>Starta hemsidan <br></br>Starta Servern (yarn start)<br></br> Starta Xampp </h2> 
                            
                        </Col>
                    </Row>

                </Container>
            </Fragment>
        );
    }

}

export default withCookies(Login); //Exporterar även withCookies funktionen 