import React, { Fragment, useState, useEffect } from 'react';
import { Button, Container, Row, Col, Form, Card, ProgressBar } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useCookies } from 'react-cookie';
import { Redirect, NavLink } from "react-router-dom";
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

//const axios = require('axios'); 
const moment = require('moment');


/*################
 För ladda upp filer 1*/



//import { Preview } from './tweetcomponents/Preview';
//#########################


export default function Tweet() {
    const [cookies, setCookie, removeCookie] = useCookies(['userId']);
    const [tweet, setTweet] = useState("");
    const [tweetList, setTweetList] = useState([{}]);
    const [tweetListAll, setTweetListAll] = useState([{}]);
    const [userList, setUserList] = useState([{}]);
    const [fileNameTest, setFileNameTest] = useState("");


    


    //laddar listan med användare från databasen
    const getUserList = () => { //processen är enkel tack vare react Hooks
        axios.get('http://localhost:3005/allusers')
        .then((res) => {
            if (res) {
                setUserList(res.data);
            }
        });       
    }


    //laddar listan med tweets från den inloggade användaren
    const getList = () => { //processen är enkel tack vare react Hooks
        axios.get('http://localhost:3005/tweets/user/' + cookies.userId)
        .then((res) => {
            if (res) {
                setTweetList(res.data);
            }
        });       
    }

//laddar listan med alla tweets
    const getListAll = () => { //processen är enkel tack vare react Hooks
        axios.get('http://localhost:3005/alltweets')
        .then((res) => {
            if (res) {
                setTweetListAll(res.data);
            }
        });       
    }


    //useEffect ser till att funktioner kan uppdateras utifrån olika klick eller liknande
    useEffect(() => {
        getList();
        getListAll();
        getUserList();
    }, []);



    /*################
    För ladda upp filer del 2*/
    const [files, setFiles] = useState([]);
    const onSuccess = (savedFiles) => {
        setFiles(savedFiles)
    };


    
    const onInputChange = (e) => {
        setFiles(e.target.files);
        console.log("e.target.files "+e.target.files);
        console.log("e.target.value "+e.target.value);

        
    };

    /*################ */



    // Redirect if not logged in, redirect är gammal syntax, använd Navigate istället https://stackoverflow.com/questions/63690695/react-redirect-is-not-exported-from-react-router-dom 
    if (!cookies.userId) {       
        return <NavLink to="/admin" />
    } 
    /*Todo*/
    

    // When the value changes for the text area
    const tweetHandleChange = (event) => {
        //Sets the value of the tweet
        setTweet(event && event.target.value ? event.target.value:"");
    };

        // When the value changes for the text area
    const fileNameHandleChange = (event) => {
        //Sets the value of the tweet
        setFileNameTest(event && event.target.value ? event.target.value:"");
    };


    function timeout(delay) { // deley function
        return new Promise( res => setTimeout(res, delay) );
    }

    // Save tweet to database
    const save = async (e) => {
        var buttondisable = e.currentTarget;//saves button as current target for when we enable it again
        buttondisable.disabled = true;//disables button to prevent spam post

        await timeout(1000); // deleys command 1 second to make sure files are uppladed

        /*Dessa 6 rader säkerställer att  */
        var tempfilename = "";
        if(fileNameTest == null){
            tempfilename = "";
        }else{
            tempfilename = fileNameTest;
        }


        if (tweet) { 
            var newFileName = "";
            //console.log(Date.now() + '-' +files[0].name);


            if(files != null) {
            e.preventDefault();
            const data = new FormData();
            for(let i = 0; i < files.length; i++) {
                var newFileName = Date.now() +'-'+ i +'-'+ files[i].name;
                console.log(newFileName);
                data.append('file', files[i], newFileName); //newFileName + files[i].name
                console.log(files[i].newFileName + files[i].name);
            }
    
            axios.post('//localhost:3005/upload', data)
                .then((response) => {
                    //toast.success('Upload Success');
                    onSuccess(response.data);
                    console.log("code works");
                    
                    //console.log(files.map((file) => `//localhost:3005/${file.filename}`));
                    //var filelocation = (file) =>  {`//localhost:3005/${file.filename}`};
                    //console.log(filelocation);
                    
                })
                .catch((e) => {
                    toast.error('Upload Error')
                    console.log("code doesnt works");
                })
            }



            axios.post('http://localhost:3005/tweets', {
                user_id: cookies.userId,
                content: tweet,
                
                file_name: newFileName

                
            })
            .then(async (res) => {
                await getList(); //updaterar listan efter man postar ett inlägg
                await getListAll();
                setTweet(null); //tömmer tweetfältet
                
                setFileNameTest(null); //tömmer tweetfältet
                //setFiles(e.target.reset()); //clears uploaded files
                //setFiles(null);
                toast.success('Upload Success');

                buttondisable.disabled = false; //Reenables button 

                if(files != "") {
                        window.location.reload(false); //Refresha sidan om man verkligen vill ha bort tidigare valt filnamn
                }
            });
        }       
    }    

    // Remove tweet
    const remove = async (id) => {
        axios.delete('http://localhost:3005/tweets/' + id)
        .then(async (res) => {
            await getList(); //updaterar listan efter man tar bort en post
            await getListAll();
        });
        
    } 
    
  if (cookies && cookies.userId) {
    setCookie('userId', cookies.userId, { 
      path: '/', 
      maxAge: process.env.REACT_APP_ENV_COOKIES_MAX_AGE //maxAge: 3600 //Cookie expiration skrivs direkt här, youtube rekommenderar .env fil, varför? https://youtu.be/4JNbKumPMHI?t=1364 maxAge: process.env.REACT_APP_ENV_COOKIES_MAX_AGE
    });
    }

    return (       
        <Fragment>           
            <Helmet>
                <meta charSet="utf-8" />
                <title>Tweet </title>
            </Helmet>       
            <Container>
                <Row>            
                    <Col>
                    <br/><Form.Label>Hello User:  
                              
                            {  userList.length && userList.map((user, index2) => {//Välkommnar personen vid namn vid inloggning 
                                return (
                                    cookies.userId == user.id && " " + user.email  
                                )}
                            )}

                        </Form.Label>
                    </Col>
                </Row>

                <Row>            
                    <Col>
                        <Form.Group controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Tweet</Form.Label>
                            <Form.Control as="textarea" rows="3" value={tweet || ""} onChange={tweetHandleChange} />
                        </Form.Group>
                        
                        {/*<Form.Group controlId="exampleForm.ControlTextarea2">
                            <Form.Label>Filename test</Form.Label>
                            <Form.Control as="textarea" rows="3" value={fileNameTest || ""} onChange={fileNameHandleChange} /> {/*Form control type file https://react-bootstrap.github.io/forms/form-control/ */}
                        {/*</Form.Group>  */}
                        
                        <Form.Group controlId="formFile" className="mb-3">
                            <Form.Label>Default file input example (max 1 file) </Form.Label>
                            <Form.Control onChange={onInputChange}  type="file" />{/*multiple , Gör det möjligt att ladda upp flera filer */} 
                        </Form.Group>

                        {/*################
                        För ladda upp filer del 3*/}
                        {/*<FileUploader onSuccess={onSuccess}/>*/}
                        {/*<Preview files={files}/>*/}
                        <ToastContainer/>
                        {/*################*/}


                        <Button variant="primary" type="button" onClick={save}>
                            Post tweet!
                        </Button> 
                        <br/>
                        <Button className="float-lg-right" variant="danger" type="button" href="/admin" onClick={() => {removeCookie('userId');}}>
                            Log out
                        </Button> 
                        <br/><br/>                    
                    </Col>
                </Row>
                <Row> {/*Kod nedan hämtar och visar de tweets som är gjorda */}
                    <Col>
                        <h2>My Tweets  </h2>                        
                            { tweetList.length && tweetList.map((item, index) => { // kollar om tweetlistan är tom och visar sedan alla tweets som finns i den 
                                return (
                                    <Card key={index} style={{ width: '18rem' }}>
                                        <Card.Body>
                                            
                                            {item.file_name !="" && (   //if no picture has been uploaded, remove card.img div                                         
                                            <Card.Img  className="mb-4" src={`//localhost:3005/${item.file_name}`}  /*sökvägen till uploaded files behövs tydligen ej*/ />
                                            )}
                                            <Card.Subtitle className="mb-2 text-muted">{moment(item.date_time).format("HH:mm:ss DD/MM/YYYY")}</Card.Subtitle>                                 
                                            <Card.Text>{item.content}</Card.Text>
                                            <Card.Text>File Name: {item.file_name}
                                            </Card.Text>
                                            <Card.Text>
                                                
                                            {  userList.length && userList.map((user, index2) => {//Emailen på personen som postade inlägget visas
                                                return (
                                                    item.user_id == user.id && "By user: " + user.email  
                                                )})
                                            }
                                            </Card.Text>
                                            <Button variant="danger" size="sm" data-id={item.id} onClick={() => remove(item.id)} >Delete</Button>
                                        </Card.Body>
                                    </Card>
                                )
                            })} 
                    </Col>

                    <Col>
                        <h2>All Tweets</h2>                        
                            {  tweetListAll.length && tweetListAll.map((item, index) => { // kollar om tweetlistan är tom och visar sedan alla tweets som finns i den  user.userId = item.user_id
                                return (
                                    <Card key={index} style={{ width: '18rem' }}>
                                        <Card.Body>
                                        
                                            {item.file_name !="" && (   //if no picture has been uploaded, remove card.img div                                         
                                                <Card.Img  className="mb-4" src={`//localhost:3005/${item.file_name}`}  /*sökvägen till uploaded files behövs tydligen ej*/ />
                                            )}                                            
                                            <Card.Subtitle className="mb-2 text-muted">{moment(item.date_time).format("HH:mm:ss DD/MM/YYYY")}</Card.Subtitle>                                 
                                            <Card.Text>{item.content}</Card.Text>
                                            <Card.Text>File Name: {item.file_name}</Card.Text>
                                            <Card.Text>U id: {" " + item.user_id }
                                                
                                            {  userList.length && userList.map((user, index2) => {//Emailen på personen som postade inlägget visas
                                                return (
                                                    item.user_id == user.id && ". Email: " + user.email  
                                                )})
                                            }


                                            </Card.Text>
                                            { cookies.userId == item.user_id && <Button variant="danger" size="sm" data-id={item.id} onClick={() => remove(item.id)} >Delete</Button>}  
                                        </Card.Body>
                                    </Card>
                                )
                            })} 
                    </Col>
                </Row>
            </Container>
        </Fragment>
        
    );

}

