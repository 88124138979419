import React, { useRef, useEffect, useState } from 'react';

import Home from './Home';



function ScrollToContact() {

    //Stopppa in usestate
    const contactScrollHere = useRef(null);


    useEffect(()=>{ //UseEffect laddas direkt när sidan laddas
        //scrollToSection(contactScrollHere);
        }, [])
        

    



  return (
<Home />    


  );
}

export default ScrollToContact;