import React, { useContext } from "react";
import heroBg from "./imgs/ovve.jpg";
import Typical from "react-typical";
import { contactLinks } from "./constants";
import { AnimatePresence, motion } from "framer-motion"; // https://stackoverflow.com/questions/69769360/error-importing-framer-motion-v5-in-react-with-create-react-app

import { Link, useMatch, useResolvedPath, NavLink } from 'react-router-dom'; 

//import profilePicture from "./imgs/ovve.jpg";
//import profilePicture2 from "./imgs/gym2.jpg";
import profilePicture from "./imgs/kostym1.jpg";
import profilePicture2 from "./imgs/kostym3.jpg";
import profilePicture3 from "./imgs/sittning.jpg";
import profilePicture4 from "./imgs/me3.jpg";
import profilePicture5 from "./imgs/van3.jpg";
import profilePicture6 from "./imgs/yourmove.jpg";
import profilePicture7 from "./imgs/saw.jpg";





import "./landingpage.css"; 


/* https://www.svgbackgrounds.com/
*/

/* CODE
yarn add framer-motion
yarn add react-typical

*/


/* Alternative code
  let shiftingimages = document.getElementById("shiftingimages");
  let myprofilepictures = ["ovve.jpg","gym.jpg","shirt.jpg"]
  setInterval(function(){  
  let randompic = Math.floor(Math.random() * 3);
  shiftingimages.src = myprofilepictures
  }, 800);
*/



//https://codesandbox.io/s/q3m16?file=/src/index.js:1294-1389
class Landingpage extends React.Component {


/* Start of switching image function : kod här https://codesandbox.io/s/q3m16?file=/src/index.js:1135-1154
*/  
  constructor(props) {
    super(props);
    this.switchImage = this.switchImage.bind(this);
    this.state = {
      currentImage: 0,
      images: [
        profilePicture2,
        //profilePicture3,
        profilePicture5,
        profilePicture4,
        profilePicture,
        profilePicture6, 
        profilePicture7,

       /* profilePicture2,*/
     
      ]
    };
  }

  switchImage() {
    if (this.state.currentImage < this.state.images.length - 1) {
  
      this.setState({
        currentImage: this.state.currentImage + 1
      });
  
    } else {
      this.setState({
        currentImage: 0
      });
    }
  }
  
  componentDidMount() {
    this.interval = setInterval(() => this.switchImage(), 7000);
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }
/* End of switching image function */  


  render() {

  return (
      <div className="landingpage-bg" >
        
        <main className="bothdivs" id="/" >
          <div className="left-landing-div"> 
            <h1 className="stortext">
            {/*<motion.span className="rubrik" > There are always another mountain to climb!  </motion.span> */}

              <motion.span className="rubrik" >My name is Fredde!</motion.span>
              {/* <span className="changingtextspan">   
                I do {" "}        */ }    
                <Typical className="changingtext"
                  wrapper='b'
                  steps={[
                    "I do IT and AI",
                    6000,
                    "Full Stack Developer",
                    6000,
                    "I build businesses",
                    6000,
                  ]}
                  loop={Infinity}
                />
              {/* </span> */}
            </h1>
            
            {/*<p className="smalltext">
              There are always another mountain to climb and another skill to learn. 
            </p> */}
                        <p className="smalltext">
              The difficult done immediately <br/> The impossible takes a litte bit longer <br/>Miracles done by appointment. 
            </p> 
            {/* I am currently working at CloudStok Technologies as a Front-End Developer 
              */}

            <div className="landing-icons ">
              {contactLinks.map((el) => (
                <a
                  href={el.link}
                  target = "_blank"
                  className="landing-icon "
                >
                  <img alt={el.name} className={el.class} src={el.url} />
                  {/* <p className="text-md mt-2 hover:hidden">{el.name}</p> */}
                </a>
              ))}
            </div>
            



          </div>


{/*

let shiftingimages = document.getElementById("shiftingimages");
let myprofilepictures = ["ovve.jpg","gym.jpg","shirt.jpg"]
setInterval(function(){  

}, 800);


let shiftingimages = document.getElementById("shiftingimages");
let myprofilepictures = ["ovve.jpg","gym.jpg","shirt.jpg"]
setInterval(function(){  
let randompic = Math.floor(Math.random() * 3);
shiftingimages.src = myprofilepictures
}, 800);



          <div className="col-lg-6 floatleft aligncontent">
            <div className="picture-div">
                {/*<div /*src={profilePicture} className="profilepicture"/>*

                <motion.img
            initial="hidden"
            whileInView={"visible"}
            variants={{
              visible: {
                y: 0,
                opacity: 1,
                transition: {
                  type: "spring",
                },
              },
              hidden: { opacity: 1, y: 80 },
            }}
            src={profilePicture}
            alt=""
            className="md:w-3/6 hidden sm:block"
            style={{width: 400, height: 400, borderRadius: 400/ 2}}
          />

            </div>
          </div> 
  */}
<div className="right-landing-div"> 

<motion.img
            initial="hidden"
            whileInView={"visible"}
            variants={{
              visible: {
                y: 0,
                opacity: 1,
                // transition: {type: "spring",},

              },
              hidden: { opacity: 1, y: 80 },
            }}
            //src={profilePicture}
            src={this.state.images[this.state.currentImage]}
            alt=""
            className="landing-profile-pic"
          />
</div>
        </main>
        
      </div>

  );
};
}

export default Landingpage;
