import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

import { Container, Row, Col } from 'react-bootstrap';
import { WorkCard } from './components/WorkCard';
import pizzas from './data.json';


import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import './WorkCarousel.css';

//yarn add bootstrap 

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  } 
};


function WorkCarousel() {
  return (
<Carousel 
    additionalTransfrom={0}
    swipeable={true}
  draggable={false} //maybe not good, do not use draggable on desktop, 
  showDots={false}
  responsive={{
    desktop: {
      breakpoint: {
        max: 3000,
        min: 1024
      },
      items: 3,
      partialVisibilityGutter: 40
    },
    mobile: {
      breakpoint: {
        max: 464,
        min: 0
      },
      items: 1,
      partialVisibilityGutter: 30
    },
    tablet: {
      breakpoint: {
        max: 1024,
        min: 464
      },
      items: 2,
      partialVisibilityGutter: 30
    }
  }}

  ssr={true} // means to render carousel on server-side.
  infinite={true}

  //autoPlay={this.props.deviceType !== "mobile" ? true : false}
  //autoPlaySpeed={1000}
  
  autoPlaySpeed={30000000}
  keyBoardControl={true}
  customTransition="all 0.8s linear" 
  containerClass="carousel-container"
  removeArrowOnDeviceType={["tablet", "mobile"]}
  //deviceType={this.props.deviceType}
  dotListClass="custom-dot-list-style"
  itemClass="carousel-item-padding-40-px"
  minimumTouchDrag={80}
  //partialVisible  /* partialVisible och centerMode är exklusiva till varanadra och båda kan ej användas, partialVisible visar delvis synligt kort på nästa medans centerMode ska visa lite av korten på båda sidaorna   https://react-multi-carousel.surge.sh/?selectedKind=Carousel&selectedStory=With%20centerMode%2C%20partially%20visibie%20on%20both%20direction&full=0&addons=1&stories=1&panelRight=0&addonPanel=kadira%2Fjsx%2Fpanel */
  centerMode
  focusOnSelect={false}
  sliderClass=""
  slidesToSlide={1} // antalet kort som finns med på varje slide punkt
  //selected blir error
    >

      {pizzas.map(data => (
      <Col xs={11} className="mb-5" >
      <WorkCard data={data}/>
      </Col>        
      ))}



      </Carousel>
  );
}

export default WorkCarousel;
