import React, { useContext, useState, useEffect  } from "react";
import { techStack } from "./constants";
import { motion } from "framer-motion";
import "./itskills.css";
import {VerticalTimeline,VerticalTimelineElement,} from "react-vertical-timeline-component"; //https://www.npmjs.com/package/react-vertical-timeline-component
import Fab from '@material-ui/core/Fab';
import PlusIcon from '@material-ui/icons/Add';


const Itskills = () => {









  // Step 1: Add isExpanded state to each tech stack item
  const [expandedItems, setExpandedItems] = useState(() => {
    // Initialize the state based on the techStack array
    return techStack.map((item) => ({ id: item.name, isExpanded: false }));
  });

  // Step 2: Click handler to toggle the isExpanded state
  const handleBoxClick = (id) => {
    setExpandedItems((prevState) =>
      prevState.map((item) => {
        if (item.id === id) {
          return { ...item, isExpanded: !item.isExpanded };
        }
        return item;
      })
    );
  };





// Step 3: Use state to manage the number of elements to display
const [displayedTechStack, setDisplayedTechStack] = useState([]);
const [showMore, setShowMore] = useState(false);


// Step 4: Function to update the displayed elements based on screen width
const updateDisplayedTechStack = () => {
  const screenWidth = window.innerWidth;
  const maxElementsToDisplay = screenWidth >= 704 ? techStack.length : 12;
  setDisplayedTechStack(techStack.slice(0, maxElementsToDisplay));
};




// Step 5: Listen for window resize events and update displayed elements accordingly
useEffect(() => {
  updateDisplayedTechStack();
  window.addEventListener("resize", updateDisplayedTechStack);
  return () => {
    window.removeEventListener("resize", updateDisplayedTechStack);
  };
}, []);


const handleShowMoreClick = () => {
  setDisplayedTechStack((prevDisplayedTechStack) => {
    const nextIndex = prevDisplayedTechStack.length + 10;
    if (nextIndex >= techStack.length) {
      setShowMore(false);
    }
    return techStack.slice(0, nextIndex);
  });
};

useEffect(() => {
  setShowMore(displayedTechStack.length < techStack.length);
}, [displayedTechStack]);



  return (
<div id="about" >
      <div   style={{
    maxWidth: "80rem",
    margin: "0 auto",
    paddingLeft: "1rem",
    paddingRight: "1rem",
    marginTop: "0",
    paddingTop: "6rem",
    paddingBottom: "3rem",
  }}>

<h2 className="provide-title">
          My IT skills
        </h2>


        <div>
          <motion.div>
            <h4 
            style={{
    //marginTop: '3rem', // Equivalent to 'mt-12' (1 rem = 16px, so 12rem = 12 * 16px = 192px)
    //fontSize: '1.875rem', // Equivalent to 'text-3xl' (1 rem = 16px, so 3 * 16px = 48px)
    //fontWeight: '600', // Equivalent to 'font-semibold'
    //color: '#3B82F6' // Equivalent to 'text-blue-500'
  }}
  className="provide-title2"
  >
              The tools I use:
            </h4>
          </motion.div>
          <motion.div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '-95px', marginBottom: "70px", justifyContent: 'space-between' }} >
            {displayedTechStack.map((el, index) => (
              <div key={el.name} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', }}>
                <motion.div
                  key={el.name}
                  initial="hidden"
                  //whileInView="visible"
                  variants={{
                    visible: {
                      y: 0,
                      opacity: 1,
                      transition: {
                        type: 'spring',
                      },
                    },
                    hidden: { opacity: 1, y: 80 },
                  }}
                  style={{
                    padding: '0.5rem 1rem', 
                    backgroundColor:  '#F9FAFB00' , //#F9FAFB20
                    margin: '1rem' , 
                    marginTop: '1.5rem', 
                    borderRadius: '0.375rem', 
                    display: 'flex', 
                    alignItems: 'center', 
                    cursor: 'pointer', 
                    transition: 'transform 0.2s', 
                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.06)', 
                    alignItems: 'center',
                    textAlign: 'center',

                   border: "2px solid #d43951",
                    color: "#d43951" /**/
                  }}
                  className={`tech-stack-box ${expandedItems.find((item) => item.id === el.name).isExpanded ? "expanded" : ""}`}
                  onClick={() => handleBoxClick(el.name)}
                >
                  {/* Add an absolute position div to capture the click event */}
                  <div
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      cursor: 'pointer',
                      pointerEvents: expandedItems.find((item) => item.id === el.name).isExpanded ? 'none' : 'auto',
                    }}
                  />
                  <img alt="" src={el.link} style={{ width: '3rem', filter: el.color ? `${el.color} grayscale(0%)` : 'grayscale(20%)', }} />
                  <h4 style={{ fontSize: '1rem', fontWeight: 'bold', marginTop: expandedItems.find((item) => item.id === el.name).isExpanded ? 'none' : '10px' }}
                  className={`tech-stack-text ${expandedItems.find((item) => item.id === el.name).isExpanded ? "textexpanded" : ""}`}
                  >
                    {el.name}
                  </h4>

                  {/* Step 3: Conditionally render the message when the box is expanded */}
                  {expandedItems.find((item) => item.id === el.name).isExpanded && (
                    <div style={{ marginTop: '0.5rem', borderTop: "1px solid #d43951" }}>
                      <div style={{ marginTop: '1rem' }}/>

                      <p>{el.message}</p>
                      <p><em> {el.since} - today</em></p>
                    </div>
                  )}
                </motion.div>
              </div>
            ))}
          </motion.div>
          
          
        {showMore && (
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center"  }}>
            <VerticalTimelineElement 
              iconOnClick={handleShowMoreClick}
              iconClassName="vertical-timeline-element-icon--button"
              icon={addButton()}
              style={{ marginLeft: "44px", marginTop: "20px" }}
            />

            {/* <button onClick={handleShowMoreClick} className="show-more-button">
              Show More
            </button>*/}
          </div>
        )}


        </div>
      </div>
    </div>
  );
};

const addButton = () => (
  <Fab className="fabbutton" classes={{ root: 'fab-button' }} color="primary" aria-label="add">
    <PlusIcon/>
  </Fab>
);

export default Itskills;