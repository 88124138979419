/*
Inspiration: 
https://andris.gauracs.com/ 
https://diogotc.com/
https://stephanemonnot.com/

Horizontal timeline https://github.com/nickolasrm/TimelineBubbles
Vertical timelinehttps://www.youtube.com/watch?v=-rnkToU2_lw&ab_channel=AleksPopovic

TODO https://www.youtube.com/watch?v=uvB4cUi4RrI&ab_channel=edutechional 



yarn add @types/react-router-dom      eller       yarn add react-router-dom
yarn add react-typical
yarn add framer-motion
yarn add react-responsive
yarn add react-router-dom
yarn add markdown-to-jsx
*/

import React, { Fragment, useState, useEffect } from 'react';

import ReactDOM from 'react-dom/client';
import reportWebVitals from '../reportWebVitals';
//import './App.scss'; //Denna behövs inte? 
import 'bootstrap/dist/css/bootstrap.min.css';
import "./Timelinedetails.css";

import timelineElements from "../components/timeline/newtimelinedata";
import Successstories from '../components/successstories/successstories';

import Markdown from 'markdown-to-jsx'; //https://www.npmjs.com/package/markdown-to-jsx
import Reactmarkdown from 'react-markdown'; //https://stackoverflow.com/questions/51060771/react-markdown-images-not-rendering-in-browser         https://www.youtube.com/watch?v=LIrK5KxsUSE&t=351s&ab_channel=Strapi
import Footer from "../components/footer/footer";


import WorkCarousel from '../components/carusel/WorkCarousel';
  const Timelinedetails = () => {

  const [currentUrl, setCurrentUrl] = useState(window.location.pathname);
    //const thisURL = () =>  {"/timeline/"+ timelineElements.name; }
  
  const currentProjectName = currentUrl.split('/').slice(-1)[0];
  const [textcontent, setTextcontent] = useState("");
  const [imagecarusellength, setSmagecarusellength] = useState(false);


  

  useEffect(() => {
    console.log(currentProjectName); 
    import (`../components/timeline/timelinetextcontent/${currentProjectName}.md`) //https://www.youtube.com/watch?v=qdTisPssu24&ab_channel=AnObjectIsA
      .then(res => {
        fetch(res.default)
          .then(res => res.text())
          .then(res => setTextcontent(res));
      })
      .catch(err => console.log(err)); 

  });


  return (
    
    <React.StrictMode>
    <div>
      <div className='background-svg-2'>
        <div className='detailsoverlay' id='timelinedetails'>     

              { timelineElements.filter(filterRightElement =>  currentUrl == "/timeline/"+ filterRightElement.name && filterRightElement.id ) //Get linkname https://stackoverflow.com/questions/39823681/read-the-current-full-url-with-react
          .map((element) => {
            const titleText = element.titlename;
            const titleTextUpperc = titleText.charAt(0).toUpperCase() + titleText.slice(1);

            const imagecarusel = element.image;
            //{element.image.length <= 1 ? setSmagecarusellength(true) : setSmagecarusellength(false) }


            return (
            <div>
                <title >{titleTextUpperc} | Fredde Holm</title> {/* TAB TITLE  */}
                
              <div className='toptest'> <Successstories imagecarusel={imagecarusel} /*imagecarusellength={imagecarusellength}*/ /> 
              </div>
              
              
              <Markdown
              //transformImageUri={uri => uri.startsWith('http') ? uri : `${process.env.IMAGE_BASE_URL}${uri}`}
              >
                {textcontent} 
              </Markdown>



            
            </div>)
          })}

        </div>
{/* <WorkCarousel /> */}

<Footer />

      </div>
    </div>  
  </React.StrictMode>
  );
}

export default Timelinedetails;