import React, { useContext } from "react";
import { serviceData } from "./constants";
import { motion } from "framer-motion";
import "./whatiprovide.css";


const Whatiprovide = () => {

    return (
    <div className="provide-allcontent">
      <div
        className="provide-allcontent2"
        id="services"
      >
        <h2 className="provide-title">
          About Me
        </h2>

        <div className="">
          <h4 className="provide-title2">
            What I Provide
          </h4>
          <div className="provide-eachcontent-all">
            {serviceData.map((el) => (
              <motion.div
                initial="hidden"
                whileInView={"visible"}
                variants={{
                  visible: { opacity: 1, scale: 1 },
                  hidden: { opacity: 0, scale: 0 },
                }}
                className="provide-eachcontent">
                <img className="provide-eachcontent-img" src={el.img} alt="" />
                <h4 className="provide-eachcontent-title">{el.name}</h4>
                <p className="provide-eachcontent-description">{el.desc}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Whatiprovide;
