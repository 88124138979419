const timelineElements = [
  {
    id: 1,
    name: "Why",
    titlename: "Why",
    category: "work",
    title: "IT Consultant at Why IT solutions AB",
    location: "Sundsvall, Sweden",
    description: "As an IT consultant I was hired to manage multiple IT systems for the local hospitals. The job included keeping the system/servers up to date along with solving unforseen IT related issues and creating system documentation.",
    lessons: "project management, administration, produce documentation, server maintenance, public sector structure",
    thumbnail: "publicassets/images/why.png", 
    image: ["why.png"], 
    startDate: "2022",
    endDate:"2023",
    specialDate: "",
    highlighted: false,
    readmore: true, 

  },
  {
    id: 2,
    name: "Sims",
    titlename: "Sims",
    category: "education",
    title: 'Student innovation school project for DIGG',
    location: "Sundsvall, Sweden",
    description: 'A 10-week school project that simulated a consulting assignment where the task was to create a website that displayed data from various countries. The project included all aspects of product development from idea to functional prototype.',
    lessons: "project management, process management, time-management/sprints, requirements analysis, UX, Javascript, React, CSS, Github",
    thumbnail: "publicassets/images/simsdigg.jpg", 
    image: ["simsdigg.jpg"],     
    startDate: "2021",
    endDate:"2021",
    specialDate: "10.w 2021",
    highlighted: false,
    readmore: true, 

  },
  {
    id: 3,
    name: "CV",
    titlename: "CV",
    category: "hobbies",
    title: "Freddeholm.se CV website",
    location: "Website",
    description: "I made this website in order to use it as a more efficient and clean CV. In this project the goal was to create a website as fast and efficient as possible while still deliver excellent quality and improving my skills with JSX and the React framework",
    lessons: "Javascript, JSX, React, Typescript, CSS, Markdown, npm/yarn, library integrations, component-based architecture, requirements capturing, problem solving",
    thumbnail: "publicassets/images/cvwebsite.jpg", 
    image: ["cvwebsite.jpg"],  
    startDate: "2023",
    endDate:"2023",
    specialDate: "2023",
    highlighted: true,
    readmore: true, 

  },
  {
    id: 4,
    name: "Studio200",
    titlename: "Studio 200 tattoo",
    category: "hobbies",
    title: "Studio 200 Tattoo website",
    location: "Mora, Sweden",
    description: "This project was initially just me building a basic website to help a friend with his new business. However the project evolved into creating a professional website featuring a contact form, Instagram integration, database connectivity, and the ability to upload content online.",
    lessons: "project management, problem solving, requirements capturing, SEO, UX, internet/web structure, HTML, CSS, PHP, mySQL",
    thumbnail: "publicassets/images/studio200tattoothumb.jpg", 
    image: ["studio200tattoo1.jpg", "studio200tattoo2.jpg", "studio200tattoo5.jpg", "studio200tattoo4.jpg", "studio200tattoo3.jpg"],  
    startDate: "2020",
    endDate:"2021",
    specialDate: "",
    highlighted: true,
    readmore: true, 

  },
  {
    id: 5,
    name: "Campervan",
    titlename: "Campervan",
    category: "hobbies",
    title: "Building a campervan",
    location: "Mora, Sweden",
    description: "A spontaneous project where the goal was to build a campervan. The project required me to learn the wide variety of handyman skills listed bellow during the 8 short week until deadline. ",
    lessons: 'carpentry, painting, flooring, insulation, electrical wiring: 12v and 230v, plumbing, project management, streamlining, "crunch" work, problem solving, flexibility',
    thumbnail: "publicassets/images/vanthumb.jpg", 
    image: ["van1.jpg", "van2.jpg"],  
    startDate: "2022",
    endDate:"2022",
    specialDate: "24/7, 8.w 2022",
    highlighted: true,
    readmore: true, 
  },
  {
    id: 7,
    name: "EarlySchool",
    titlename: "Early School",
    category: "education",
    title: "Preschool to 6th grade",
    location: 'Utmelandsskolan, Mora, Sweden',
    description: "Standard Swedish pre school, elementary school and middle school education.",
    lessons: "",
    thumbnail: "publicassets/images/utmeland.jpg", 
    image: ["utmeland.jpg"],  
    startDate: "2002",
    endDate:"2009",
    specialDate: "",
    highlighted: false,
    readmore: false, 
  },
  {
    id: 8,
    name: "Highschool",
    titlename: "High school",
    category: "education",
    title: "7th to 9th grade",
    location: 'Morkarlbyhöjdens skola, Mora, Sweden',
    description: 'Standard Swedish high school education.',
    lessons: "",
    thumbnail: "publicassets/images/morkarlby.jpg", 
    image: ["morkarlby.jpg"],  
    startDate: "2009",
    endDate:"2012",
    specialDate: "",
    highlighted: false,
    readmore: true, 
  },
  {
    id: 9,
    name: "Seniorhighschool",
    titlename: "Senior high school",
    category: "education",
    title: "10th to 12th grade - technological science",
    location: 'S:t Mikaelsskolan, Mora, Sweden',
    description: 'Swedish senior high school education with the technological science orientation. Technological science included subjects such as electrical engineering, electronics, microcomputer technology, embedded systems and programming. Alongside the standard curriculum, I also studied advanced Mathematics and English.',
    lessons: "",
    thumbnail: "publicassets/images/stmikael.jpg", 
    image: ["stmikael.jpg"],  
    startDate: "2012",
    endDate:"2015",
    specialDate: "",
    highlighted: false,
    readmore: true, 
  },
  {
    id: 10,
    name: "Moramunicipality",
    titlename: "Mora municipality",
    category: "work",
    title: "Technical administrator at Mora municipality",
    location: "Mora, Sweden",
    description: "Internship turned into full-time position. The position included all possible tasks within office administration, this included but was not limited to customer support, invoice management, planning fiber expansion and creation and implementation of smaller projects.",
    lessons: "independence, responsibility, problem solving, cooperation, work ethic, corporate culture, public sector structure",
    thumbnail: "publicassets/images/morakommun.jpg", 
    image: ["morakommun.jpg"], 
    startDate: "2015",
    endDate:"2017",
    specialDate: "",
    highlighted: false,
    readmore: true, 
  },
  {
    id: 6,
    name: "Miun",
    titlename: "University",
    category: "education",
    title: "Masters degree of Science in Industrial Engineering and Management",
    location: "Mid Sweden University, Sundsvall, Sweden",
    description: "Civil engineering education with a focus on economics, leadership and IT with a lot of math and programming in the mix. This program taught me a little bit about a lot of subjects and prepared me to handle any challenge and types of situations.",
    lessons: "problem solving, flexibility, structure, learning speed, leadership, independence, planning, the courses included in the program",
    thumbnail: "publicassets/images/miun.jpg", 
    image: ["miun.jpg"], 
    startDate: "2017",
    endDate:"2022",
    specialDate: "",
    highlighted: true,
    readmore: true, 
  },
  {
    id: 11,
    name: "Fraternities",
    titlename: "Fraternities",
    category: "hobbies",
    title: "Board member at fraternities",
    location: "Sundsvall, Sweden",
    description: "Voluntary fraternity board work for the student organizations Mytec and SÅS at Mid-Sweden University. My responsibilities included event planning and execution along side product purchases and sales. In SÅS, I was also responsible for finances and bookkeeping.",
    lessons: "accounting, budgeting, decision-making, coordination, collaboration, planning, conflict management",
    thumbnail: "publicassets/images/fraterneties.jpg", 
    image: ["fraterneties.jpg"],  
    startDate: "2017",
    endDate:"2022",
    specialDate: "",
    highlighted: false,
    readmore: true, 
  },

//TODO

  {
    id: 12,
    name: "SundsvallStudentRecruiting",
    titlename: "Sundsvall Student Recruiting",
    category: "work",
    title: "Sundsvall Student Recruiting",
    location: "Sundsvall, Sweden",
    description: "Creating a recruitment company that helped companies connect with students and vice versa.",
    lessons: "marketing, sales, web development, business legality, resilience",
    thumbnail: "publicassets/images/ssturec1.jpg", 
    image: ["ssturec1.jpg", "ssturec2.jpg"],  
    startDate: "2022",
    endDate:"2023",
    specialDate: "",
    highlighted: false,
    readmore: true, 
  },
  {
    id: 13,
    name: "AldrigJag",
    titlename: "AldrigJag",
    category: "work",
    title: "AldrigJag.se",
    location: "Website - shop",
    description: "A company I created that sells self defence products including legal variants of pepper spray, personal alarms and more. The webshop created was coded from the bottom up by myself.",
    lessons: "marketing, sales, web development, decision-making, business legality, taxation, resilience, risk management, market research, customer service",
    thumbnail: "publicassets/images/aldrigjag1.jpg", 
    image: ["aldrigjag1.jpg", "aldrigjag2.jpg", "aldrigjag3.jpg", "aldrigjag4.jpg"],  
    startDate: "2023",
    endDate:"2023.1",
    specialDate: "2023 - Today",
    highlighted: false,
    readmore: true, 
  },
  {
    id: 13,
    name: "Studentshoppen",
    titlename: "Studentshoppen",
    category: "work",
    title: "Studentshoppen.com",
    location: "Website - shop",
    description: "A company I created that sells party products and accessories targeted towards university students. The webshop created was coded from the bottom up by myself.",
    lessons: "marketing, sales, web development, product creation, photoshop, business legality, taxation, risk management, market research, customer service",
    thumbnail: "publicassets/images/studentshoppen0.jpg", 
    image: ["studentshoppen0.jpg", "studentshoppen1.jpg", "studentshoppen2.jpg", "studentshoppen3.jpg", "studentshoppen4.jpg", "studentshoppen5.jpg"],  
    startDate: "2023",
    endDate:"2023.2",
    specialDate: "2023 - Today",
    highlighted: true,
    readmore: true, 
  },
  {
    id: 14,
    name: "ReceiptApp",
    titlename: "ReceiptApp",
    category: "work",
    title: "Receipt App",
    location: "Website",
    description: "I needed a cheap way to give customers reciepts either by sending them via email or print them out physicaly for when I sold products in person. So I created my own recipt app in React to solve this problem.",
    lessons: "speed, web development, react email handeling, EmailJs, React, hardware integration, user experience",
    thumbnail: "publicassets/images/kvitto2.jpg", 
    image: ["kvitto2.jpg", "kvitto1.jpg"],  
    startDate: "2023",
    endDate:"2023.05",
    specialDate: "2 days, 2023",
    highlighted: false,
    readmore: true, 
  },
];

export default timelineElements;
