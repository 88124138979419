import React,{ Component, Fragment, useState, useEffect } from "react";
import "./NavbarStyles.css";

import { useMediaQuery } from 'react-responsive';
import { HashLink } from "react-router-hash-link"; //https://www.npmjs.com/package/react-router-hash-link
import {useLocation, useNavigate } from "react-router-dom";

//import Student from "../pages/studentinfo";
//import Company from "../pages/companyinfo";
//import App from "../../App";

//import { Link, useMatch, useResolvedPath, NavLink } from 'react-router-dom'; 
import ssturecbanner from './mountaintestlogo.png';
import ssturecbanner2 from './smallssturecbanner.png';
// Important info for refreshing page on for example /student https://stackoverflow.com/questions/27928372/react-router-urls-dont-work-when-refreshing-or-writing-manually?page=1&tab=scoredesc#tab-top 
import  {Link} from "react-scroll";

/* CODE
yarn add react-responsive

*/


// video https://www.youtube.com/watch?v=23BHwAFIZmk&ab_channel=Tech2etc



const Navbar = () => {
  const [clicked, setClicked] = useState(false);
  const location = useLocation();
  
  const [name, setName] = useState("");

  const [changenavcolor, setChangenavcolor] = useState('navbarstyle navbarstylecolor1');
  const [changebannercolor, setChangebannercolor] = useState('banner1');
  const [navbarid, setNavbarid] = useState('navbarid1');

 
  const closeClickMenue = () => {
    setClicked(false);
  }

  const handleClick = () => {
    setClicked(!clicked);
  }

  const handleStartClick = () => {
    window.location.href='/';
  }




useEffect(() => {
  console.log(location.pathname);


  function handleScroll() {
    if (window.scrollY > 1600) {
      setChangenavcolor('navbarstyle navbarstylecolor2'); //navbarstyle navbarstylecolor2
      setChangebannercolor('banner2'); 
      setNavbarid('navbarid2'); 


    } else {
      setChangenavcolor('navbarstyle navbarstylecolor1');
      setChangebannercolor('banner1');
      setNavbarid('navbarid1'); 

    }
  }

  window.addEventListener('scroll', handleScroll);

  return () => {
    window.removeEventListener('scroll', handleScroll);
  };
  

}, []);



    return (
      <>
        <nav className={ location.pathname == "/" ? changenavcolor : "navbarstyle navbarstylecolor2"}> 

          <a  href="/" onClick={clicked && handleClick}> {/*href uppdaterar webläsaren och to byter bara vad som visas */}
            <img className={location.pathname == "/" ? changebannercolor : "banner2"} src={ssturecbanner}></img>

{/*
            <svg //för loggan
              id="logo-15"
              width="49"
              height="48"
              viewBox="0 0 49 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              {" "}
              <path //varje path är en cirkel i loggan
                d="M24.5 12.75C24.5 18.9632 19.4632 24 13.25 24H2V12.75C2 6.53679 7.03679 1.5 13.25 1.5C19.4632 1.5 24.5 6.53679 24.5 12.75Z"
                className="ccustom"
                fill="#e0972a"
              ></path>{" "}
              <path
                d="M24.5 35.25C24.5 29.0368 29.5368 24 35.75 24H47V35.25C47 41.4632 41.9632 46.5 35.75 46.5C29.5368 46.5 24.5 41.4632 24.5 35.25Z"
                className="ccustom"
                fill="#e0972a"
              ></path>{" "}
              <path
                d="M2 35.25C2 41.4632 7.03679 46.5 13.25 46.5H24.5V35.25C24.5 29.0368 19.4632 24 13.25 24C7.03679 24 2 29.0368 2 35.25Z"
                className="ccustom"
                fill="#e0972a"
              ></path>{" "}
              <path
                d="M47 12.75C47 6.53679 41.9632 1.5 35.75 1.5H24.5V12.75C24.5 18.9632 29.5368 24 35.75 24C41.9632 24 47 18.9632 47 12.75Z"
                className="ccustom"
                fill="#e0972a"
              ></path>{" "}
            </svg> 

        */}
          </a>

          <div>  
            <ul
              id={location.pathname == "/" ? navbarid : "navbarid2"}
              className={clicked ? "navbar active navbaridthing" : "navbar navbaridthing"}
            >

{/* https://www.youtube.com/watch?v=QzW03hyw_bU&ab_channel=CodeCommerce */} 
{/* Savior: https://stackoverflow.com/questions/69147761/how-to-use-a-navbar-in-react-app-to-reroute-to-a-page-then-scroll-to-an-element */}
{/* https://stackoverflow.com/questions/24665602/scrollintoview-scrolls-just-too-far*/}
{/* Navlink gör automatiskt den länk man är på till aktiv, end gör att man måste vara inne på den exakta länken för att vara aktiv  https://stackoverflow.com/questions/64718464/navlink-exact-prop-not-working-for-react-router-dom-6 */}
              <li className='nav-item'> 
              {location.pathname == "/" ?
              <Link to="scrolltostart"  spy={true}  smooth={true} offset={0} duration={0} onClick={closeClickMenue} scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'center' }) } >Start</Link>
              :<HashLink smooth to="/#start" className={`${location.pathname}${location.hash}` === "/#start" ? "active" : ""} activeClassName="" onClick={closeClickMenue} scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'center' }) } >Start</HashLink>  
              }  </li>

              <li className='nav-item'>
              {location.pathname == "/" ?
                <Link to="scrolltoabout"  spy={true}  smooth={true} offset={0} duration={0} onClick={closeClickMenue} scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'center' }) } >About</Link>
                :<HashLink smooth to="/#about" className={`${location.pathname}${location.hash}` === "/#about" ? "active" : ""} activeClassName="" onClick={closeClickMenue} scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start' }) } >About</HashLink>  
              }  </li>


              <li className='nav-item'>
              {location.pathname == "/" ?
                <Link to="scrolltoexpertise"  spy={true}  smooth={true} offset={0} duration={0} onClick={closeClickMenue} scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'center' }) } >Expertise</Link>
                :<HashLink smooth to="/#expertise" className={`${location.pathname}${location.hash}` === "/#expertise" ? "active" : ""} activeClassName="" onClick={closeClickMenue} scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start' }) } >Expertise</HashLink>  
              }  </li>

              <li className='nav-item'>
              {location.pathname == "/" ?
                <Link to="scrolltoitskills"  spy={true}  smooth={true} offset={0} duration={0} onClick={closeClickMenue} scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'center' }) } >IT skills</Link>
                :<HashLink smooth to="/#itskills" className={`${location.pathname}${location.hash}` === "/#itskills" ? "active" : ""} activeClassName="" onClick={closeClickMenue} scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start' }) } >IT skills</HashLink>  
              }  </li>




              <li className='nav-item'>
              {location.pathname == "/" ?
              <Link  to="scrolltotimeline" spy={true}  smooth={true} offset={0} duration={0}  onClick={closeClickMenue} scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'center' }) } >Timeline</Link> 
              :<HashLink smooth to="/#timeline" className={`${location.pathname}${location.hash}` === "/#timeline" ? "active" : ""} activeClassName="" onClick={closeClickMenue} scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'center'}) } >Timeline</HashLink>  
              }  </li> {/* scroll issue with timeline: https://github.com/rafgraph/react-router-hash-link/issues/84 */}

              <li className='nav-item'>
              {location.pathname == "/" ?
              <Link  to="scrolltocontact" spy={true}  smooth={true} offset={0} duration={0}  onClick={closeClickMenue} scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'center' })} >Contact</Link> 
              :<HashLink smooth to="/#contact" state={{ prevPath: location.pathname }} className={`${location.pathname}${location.hash}` === "/#contact" ? "active" : ""} activeClassName="" onClick={closeClickMenue} scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'center'}) } >Contact</HashLink> 
              }  </li>




              {/*<li className='nav-item'>
                <Link to="scrolltostart"  spy={true}  smooth={true} offset={0} duration={0} onClick={closeClickMenue} scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'center' }) } >Start</Link>

              </li>

              <li className='nav-item'>
                <Link to="scrolltoabout"  spy={true}  smooth={true} offset={0} duration={0} onClick={closeClickMenue} scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'center' }) } >About</Link> 
              </li>

              <li className='nav-item'> 
              <Link  to="scrolltotimeline" spy={true}  smooth={true} offset={0} duration={0}  onClick={closeClickMenue} scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'center' }) } >Timeline</Link> 
              </li> 
              
              <li className='nav-item'>
              <Link  to="scrolltocontact" spy={true}  smooth={true} offset={0} duration={0}  onClick={closeClickMenue} scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'center' })} >Contact</Link> 
              </li>*/}



            </ul>
          </div>

          <div id="mobile" onClick={handleClick}>
            <i
              id="bar" //yarn add @fortawesome/react-fontawesome
              className={clicked ? "fas fa-times" : "fas fa-bars"} // inkludera font-awesome stycket i 
            ></i>
          </div>
        </nav>




      </>
    
    );
  }





export default Navbar;
