//Youtube tutorial https://www.youtube.com/watch?v=-rnkToU2_lw&ab_channel=AleksPopovic 
//yarn install
//yarn add react-vertical-timeline-component

// yarn add @material-ui/core
//yarn add @material-ui/icons
//yarn add antd



import React, { useEffect, useState } from 'react';

import "./VerticalTimeline.css";
import { ReactComponent as WorkIcon } from "./timelineassets/newwork.svg"; //#### jobb skola etc Ikoner 
import { ReactComponent as SchoolIcon } from "./timelineassets/school.svg"; //jobb skola etc Ikoner 
import { ReactComponent as HobbyIcon } from "./timelineassets/kite.svg"; //#### jobb skola etc Ikoner 
import { ReactComponent as OldworkIcon } from "./timelineassets/work.svg"; //#### jobb skola etc Ikoner 
import { ReactComponent as Starsvg } from "./timelineassets/star2.svg"; //#### jobb skola etc Ikoner 

import Fab from '@material-ui/core/Fab';
import PlusIcon from '@material-ui/icons/Add';
import Star from '@material-ui/icons/Star'; //https://mui.com/material-ui/material-icons/?query=star

import Selectoption from "./selectoption/selectoption"
import "./selectoption/selectoption.css"

import studentsvg from "./timelineassets/school.svg";//'./selectoption/studentwhite.svg'
import allsvg from "./timelineassets/all.svg";//'./selectoption/studentwhite.svg'
import kitesvg from "./timelineassets/kite.svg";//'./selectoption/studentwhite.svg'
import worksvg from "./timelineassets/newwork.svg";//'./selectoption/studentwhite.svg'

import { Link, useMatch, useResolvedPath, NavLink } from 'react-router-dom'; 


import {Switch} from "antd";
//import "antd/dist/antd.css";

import HighlightedIcon from './timelineassets/star3.svg';


//import timelineElements from "./timelineElements"; //old timeline
import timelineElements from "./newtimelinedata";

import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component"; //https://www.npmjs.com/package/react-vertical-timeline-component

import "react-vertical-timeline-component/style.min.css";
import { HashLink } from "react-router-hash-link"; //https://www.npmjs.com/package/react-router-hash-link


const VerticalLoadMoreTimeline = () => {
  const [itemcount, setItemcount] = useState(1); // 1 + 3 nedan i set itemcount gör att 4 events visas först och sedan + 3 event för varje + klick 

  const [onlyhighlighted, setOnlyhighlighted] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("all");


  const toggleHighlighted = () => {
    onlyhighlighted == false ? setOnlyhighlighted(true): setOnlyhighlighted(false);
    console.log(onlyhighlighted);
    
    
    {onlyhighlighted == true ?
    console.log(timelineElements.filter(filtering => filtering.highlighted==true).length ) :
    console.log(timelineElements.filter(filtering => filtering.id).length )}
    console.log(elementsToRender.length )
    console.log(selectedCategory);

  }

  useEffect(() => {
    loadMore();
  }, []);

  const loadMore = () => {
    setItemcount(itemcount + 3)
  };


  const elementsToRender = timelineElements
  //.concat()
  .filter(filteringCategory => selectedCategory == "all" ?  filteringCategory.category : selectedCategory == "hobbies" ? filteringCategory.category=="hobbies" : selectedCategory == "education" ? filteringCategory.category=="education" : filteringCategory.category=="work"  ) 

  .filter(filteringHighlight => onlyhighlighted == true ?  filteringHighlight.highlighted==true : filteringHighlight.id) //filter              //shows only highlighted posts :: example https://upmostly.com/tutorials/react-filter-filtering-arrays-in-react-with-examples
  .sort(  (a,b) => (a.endDate - b.endDate) == 0 
  ? a.startDate > b.startDate ? -1 : 1 
  : (a.endDate - b.endDate) > 0 ? -1 : 1) // Dessa 3 rader sorterar efter slutdatum, om slutdatum är lika sorterar man efter startdatum

  






  const workIconStyles = { background: "#963cb1" }; //#### Bakgrundsfärg till SVG loggor 
  const schoolIconStyles = { background: "#0bb99c" }; //Bakgrundsfärg till SVG loggor 
  const HobbyStyles = { background: "#42a73f" }; //Bakgrundsfärg till SVG loggor 
  //const kiteIconStyles = { background: "#1b06d6" }; //Bakgrundsfärg till SVG loggor 
  //let moreTimelineIconStyle = { background: "#f116c2", hover:"#4e093f" }; //Bakgrundsfärg till SVG loggor 

  //const filterstuff = filtering =>  filtering.id;
  //const filterstuff = filtering =>  filtering.highlighted==true;


  const categoryHandler = (event) => { 
    setSelectedCategory(event.target.value); //Tror inte denna funktion behövs när vi satt value={selectedCategory} på selected

  }

  return (
  <div>
    <div className="timeline-overhead">
      <div className="timeline-overhead-left">
        
      </div>
      
      <div className="timeline-overhead-center">
        <h1 className="timelinetitle timelinefordesktop">My Timeline</h1>
        <h1 className="timelinetitle timelineformobile">Timeline</h1>

      </div>

      <div className="timeline-overhead-right">

       <span className='filtertimeline'><Switch  className='switch-style' onClick={toggleHighlighted} checkedChildren={<Starsvg/>} unCheckedChildren="All"/> {/* https://ant.design/components/switch */} 



{/* Style https://codepen.io/himalayasingh/pen/pxKKgd https://stackoverflow.com/questions/63484886/get-value-of-select-onchange-in-functional-component */} 
      {/*<select value={selectedCategory} onChange={categoryHandler} > 
        <option value={"all"}>All Categories</option>   
        <option value={"work"}>Work</option>
        <option value={"education"}>Education</option>
        <option value={"hobbies"}>Hobbies</option>
      </select>  
*/}



      <form id="app-cover" value={selectedCategory} onChange={categoryHandler}>
        <div id="select-box">
          <input type="checkbox" id="options-view-button" value={selectedCategory} onChange={categoryHandler}/>
          <div id="select-button" className="brd">
            <div id="selected-value">
              <span>Select a platform</span>
            </div>
            {/*<div id="chevrons"><i className="fas fa-chevron-down"></i> </div>
            <div id="chevrons">
              <i className="fas fa-chevron-up"></i>
              <i className="fas fa-chevron-down"></i>
            </div> */}
          </div>

          <div id="options">
            <div className="option" >
              <input className="s-c top radioinput-select" type="radio" name="platform" value="all" checked={selectedCategory == "all" ? true : false} />
              <input className="s-c bottom radioinput-select" type="radio" name="platform" value="all" />
              <img src={allsvg} className="selectsvg "></img>
              <span className="label-select">All Categories</span>
              <span className="opt-val">All Categories</span>
            </div>

            <div className="option">
              <input className="s-c top radioinput-select" type="radio" name="platform" value="work" />
              <input className="s-c bottom radioinput-select" type="radio" name="platform" value="work" />
              <img src={worksvg} className="selectsvg "></img>
              <span className="label-select">Work</span>
              <span className="opt-val">Work</span>
            </div>
            <div className="option">
              <input className="s-c top radioinput-select" type="radio" name="platform" value="education" />
              <input className="s-c bottom radioinput-select" type="radio" name="platform" value="education" />
              <img src={studentsvg} className="selectsvg fab fa-behance"></img>
              <span className="label-select">Education</span>
              <span className="opt-val">Education</span>
            </div>
           <div className="option">
              <input className="s-c top radioinput-select" type="radio" name="platform" value="hobbies" />
              <input className="s-c bottom radioinput-select" type="radio" name="platform" value="hobbies" />
              <img src={kitesvg} className="selectsvg fab fa-behance"></img>
              <span className="label-select">Hobbies</span>
              <span className="opt-val">Hobbies</span>
            </div>
            
            {/*<div className="option">
              <input className="s-c top radioinput-select" type="radio" name="platform" value="stackoverflow" />
              <input className="s-c bottom radioinput-select" type="radio" name="platform" value="stackoverflow" />
              <img src={studentsvg} className="selectsvg fab fa-behance"></img>
              <span className="label-select">StackOverflow</span>
              <span className="opt-val">StackOverflow</span>
            </div>

            <div className="option">
              <input className="s-c top radioinput-select" type="radio" name="platform" value="freecodecamp" />
              <input className="s-c bottom radioinput-select" type="radio" name="platform" value="freecodecamp" />
              <img src={studentsvg} className="selectsvg fab fa-behance"></img>
              <span className="label-select">FreeCodeCamp</span>
              <span className="opt-val">FreeCodeCamp</span>
            </div>  */}
            <div id="option-bg"></div>
          </div>
        </div>
      </form>






      </span>

      </div>

</div>   


<div className='timeline-content'> 
      <VerticalTimeline className={"vertical-timeline-custom-line vertical-timeline-custom-line-highlighted--"+onlyhighlighted}  /* gives the timeline custom color w css file */> 
      { elementsToRender
      .slice(0, itemcount) // how to select amount of elements shown https://stackoverflow.com/questions/57236204/how-to-return-only-3-elements-from-arrays-of-object-in-react
  .map((element) => {
          let isWorkIcon = element.category === "work"; //#### Lägg till alla iconer här utom en, svg importeras högst upp och färg väljs under app  
          let isSchoolIcon = element.category === "education";
          let isHobbyIcon = element.category === "hobbies";

          /* Old showbutton function   
          let showButton =
            element.buttonText !== undefined &&
            element.buttonText !== null &&
            element.buttonText !== ""; */

          return (
            <VerticalTimelineElement
              key={element.key}
              date={element.specialDate == "" ? element.endDate > 4000 ? element.startDate + " - present" : element.startDate + " - "+ element.endDate : element.specialDate}
              dateClassName="date"
              iconStyle={isWorkIcon ? workIconStyles : isSchoolIcon ? schoolIconStyles : HobbyStyles } //#### If else statement tex, if isWorkicon så workicon else if isschoolicon så schoolicon else if etc
              icon={isWorkIcon ? <WorkIcon /> : isSchoolIcon ? <SchoolIcon /> :  <HobbyIcon/> } //#### If else statement tex, if isWorkicon så workicon else if isschoolicon så schoolicon else if etc
              className={onlyhighlighted ? "content_shadow vertical-timeline-element--"+element.category : "content_shadow"} //till för om man vil ändra vita ringen runt varje ikon
            >
              
              <div className='timelineimagediv'><img src={element.thumbnail} /*Använd följande:  src={element.thumbnail} {element.id} */ className="timelineimage" alt="fireSpot"/> </div>
              <h3 className="vertical-timeline-element-title"> <br/>
               {element.title} {element.highlighted == true ? <img src={HighlightedIcon} alt="Highlighted event" className="highlighted"/> : ""}
              </h3> 
              <h5 className="vertical-timeline-element-subtitle">
                {element.location}
              </h5>
              <p id="description">{element.description}</p>
              
              {element.lessons != "" ? <p className="lessons" id="lessons">
                Lessons: <span className="actuallessons">{element.lessons}</span> 
              </p>
              : <div style={{ marginTop: '22px' }}/>
              }
             
              {element.readmore && //if readmore=true then show button
              <a className={`readmorebutton ${
                    isWorkIcon ? "workButton" : isSchoolIcon ? "schoolButton" : isHobbyIcon ? "newworkButton" : "hobbyButton"
                  }`}
                  //href={element.buttonLink}
                  //timelinedetails
                  href={`/timeline/${element.name}`}
                  target="_self">
                  Read more
                 { /*{element.buttonLink}*/}
                </a>}

                { /*<Link key={movie.title ? movie.title : movie.name} to={`/detail/${type}/${movie.id}`} >  </Link>            
                <NavLink end to="/company" target="_self" onClick={this.state.clicked && this.handleClick} >Företag</NavLink> */}

              {/* Old showbutton function 
              {showButton && (
                <a
                  className={`button ${
                    isWorkIcon ? "workButton" : isSchoolIcon ?"schoolButton" : isHobbyIcon ? "newworkButton" : "hobbyButton"
                  }`}
                  href="/"
                >
                  {element.buttonText}
                </a>
              )}*/}

            </VerticalTimelineElement>

          );



        })}




         
         {itemcount < elementsToRender.length  
          && ( 
        <VerticalTimelineElement 
          iconOnClick={loadMore}
          iconClassName="vertical-timeline-element-icon--button"
          icon={addButton()}
        />
        )}
      
      </VerticalTimeline>
      </div>
            
    </div>
  );
}

//const [itemCount, setCount] = useState(2);






const addButton = () => (
  <Fab className="fabbutton" classes={{ root: 'fab-button' }} color="primary" aria-label="add">
    <PlusIcon/>
  </Fab>
);

export default VerticalLoadMoreTimeline;
