export const techStack = [ //icons found here: https://github.com/devicons/devicon/tree/master/icons
//filter picker: https://codepen.io/sosuke/pen/Pjoqqp
  {
    id: 14,
    name: "Javascript",
    link: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/javascript/javascript-original.svg",
    message: "My main coding language. I have used JS to code several websites with the react framework or html/php.",
    since: "2019",
  },
  {
    id: 16,
    name: "React",
    link: "https://raw.githubusercontent.com/devicons/devicon/master/icons/react/react-original-wordmark.svg",
    message: "The main framework I use for creating my websites. For example, this website is made in React.",
    since: "2021",

  },
  {
    id: 2,
    name: "TypeScript",
    link: "https://raw.githubusercontent.com/devicons/devicon/master/icons/typescript/typescript-original.svg",
    message: "Typescript is used to create many of my websites when using React, either for entire websites or for specific pages/functions.",
    since: "2022",
  },
  { //icons found here: https://github.com/devicons/devicon/tree/master/icons
    id: 1,
    name: "C#",
    link: "https://raw.githubusercontent.com/devicons/devicon/master/icons/csharp/csharp-original.svg",
    message: "My main coding language when it comes to non-web softwares and game development.",
    since: "2021",
  },
  {
    id: 10,
    name: "CSS",
    link: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/css3/css3-plain.svg",
    message: "The main styling tool I use when making all of my websites.",
    since: "2018",
  },
  {
    id: 9,
    name: "HTML",
    link: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/html5/html5-original.svg",
    message: "The programing language I used to create my first websites along with PHP. I now mainly make websites using React, however knowledge in HTML is still important for this.",
    since: "2018",
  },
  {
    id: 6,
    name: "Php",
    link: "https://raw.githubusercontent.com/devicons/devicon/master/icons/php/php-plain.svg",
    message: "The programing language I used to create my first websites along with HTML. I now mainly make websites using React.",
    since: "2018",
  },




  {
    id: 5,
    name: "Unity",
    link: "https://raw.githubusercontent.com/devicons/devicon/master/icons/unity/unity-original.svg",
    message: "The main platform and engine I use for game development.",
    since: "2020",
    color: "invert(100%) sepia(0%) saturate(0%) hue-rotate(251deg) brightness(107%) contrast(109%)", //white
  },
  {
    id: 7,
    name: "Sanity.io",
    link: "publicassets/svgs/sanity.svg",
    message: "The main CMS(customizable, headless content management system) and database I use for my websites. For example it manages my products for my e-commerce websites.",
    since: "2023",
  },

  {
    id: 11,
    name: "Bootstrap",
    link: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/bootstrap/bootstrap-plain.svg",
    message: "I have been using the Bootstrap framework to style many of my websites, but I usualy combine it with custom CSS style.",
    since: "2022",
  },
  {
    id: 12,
    name: "Next.js",
    link: "https://raw.githubusercontent.com/devicons/devicon/master/icons/nextjs/nextjs-original-wordmark.svg",
    message: "Next.js is the main framework I use for server-side rendering in my React apps. For example my e-commerce websites.",
    since: "2023",
    color: "invert(100%) sepia(0%) saturate(0%) hue-rotate(251deg) brightness(107%) contrast(109%)", //white

  },
  /*{
    id: 13,
    name: "Sass",
    link: "https://raw.githubusercontent.com/devicons/devicon/master/icons/sass/sass-original.svg",
    message: "Sass is a popular CSS preprocessor that extends the capabilities of CSS with features like variables, nested rules, mixins, and more. It helps developers write more maintainable and organized stylesheets, reducing duplication and improving development efficiency.",
  },*/

  {
    id: 15,
    name: "Photoshop",
    link: "https://upload.wikimedia.org/wikipedia/commons/a/af/Adobe_Photoshop_CC_icon.svg",
    message: "I use Photoshop to create logos, sprites and other digital assets I need for my websites and other projects.",
    since: "2015",
  },
  {
    id: 21,
    name: "Firebase",
    link: "https://www.vectorlogo.zone/logos/firebase/firebase-icon.svg",
    message: "I use Firebase for its database and authentication functions in order to manage data and login functions for several of my websites.",
    since: "2023",
  },



  {
    id: 17,
    name: "MySQl",
    link: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/mysql/mysql-plain.svg",
    message: "I used MYSQL databases to fetch and store data in all of my websites made in PHP or HTML.",
    since: "2018",
  },
  {
    id: 20,
    name: "Github",
    link: "https://raw.githubusercontent.com/devicons/devicon/master/icons/github/github-original.svg",
    message: "I use GitHub to store my personal projects and collaborate on shared projects",
    since: "2021",
    color: "invert(100%) sepia(0%) saturate(0%) hue-rotate(251deg) brightness(107%) contrast(109%)", //white


  },


  /*{
    id: 15,
    name: "Tailwind CSS",
    link: "https://www.vectorlogo.zone/logos/tailwindcss/tailwindcss-icon.svg",
    message: "Tailwind CSS is a utility-first CSS framework that provides a set of pre-built classes to style web elements. It allows developers to rapidly build custom designs by composing utility classes, which saves time in writing custom CSS styles from scratch.",
  },*/



  {
    id: 15,
    name: "Netlify",
    link: "publicassets/svgs/netlify.svg",
    message: "I use Netlify to host all of my projects that requires a more advanced backend or server-side rendering. For example my e-commerce websites.",
    since: "2023",
  },

  {
    id: 15,
    name: "Markdown",
    link: "https://raw.githubusercontent.com/devicons/devicon/master/icons/markdown/markdown-original.svg",
    message: "I use Markdown to manage large amounts of text for some of my websites that do not need an advanced backend. This website uses Markdown to manage the text in the timeline element.",
    since: "2022",
    color: "invert(100%) sepia(0%) saturate(0%) hue-rotate(251deg) brightness(107%) contrast(109%)", //white

  },
  {
    id: 15,
    name: ".Net",
    link: "https://raw.githubusercontent.com/devicons/devicon/master/icons/dotnetcore/dotnetcore-original.svg",
    message: "I use .Net to build desktop applications.",
    since: "2022",
  },
  /*{
    id: 8,
    name: "React Native",
    link: "https://pagepro.co/blog/wp-content/uploads/2020/03/react-native-logo-884x1024.png",
    message: "React Native is a popular framework for building mobile applications using React and JavaScript. It enables developers to create cross-platform mobile apps that look and feel native on both iOS and Android devices. React Native's component-based architecture allows for code reuse and faster development cycles.",
    since: "2022",
  },*/
  {
    id: 15,
    name: "FileZilla",
    link: "https://raw.githubusercontent.com/devicons/devicon/master/icons/filezilla/filezilla-plain.svg",
    message: "I use FileZilla as my primary File Transfer Protocol to upload many of my non-Netlify websites to website hosting services.",
    since: "2018",

  },
  {
    id: 4,
    name: "NodeJS",
    link: "https://raw.githubusercontent.com/devicons/devicon/master/icons/nodejs/nodejs-original-wordmark.svg",
    message: "I use NodeJS to building server-side applications and web servers.",
    since: "2021",
  },

  {
    id: 3,
    name: "Express",
    link: "https://raw.githubusercontent.com/devicons/devicon/master/icons/express/express-original-wordmark.svg",
    message: "I use Express as a framework for NodeJS to build some web apps and APIs.",
    since: "2022",
    color: "invert(100%) sepia(0%) saturate(0%) hue-rotate(251deg) brightness(107%) contrast(109%)", //white

  },
  // ... other tech stack items ...
];

export const contactLinks = [
{ name: 'Email', class: 'contacticons-filter landingpage-contacticons-exception', url: 'publicassets/svgs/email4.svg', link: 'mailto:Freddieholm@hotmail.com' },
{ name: 'Instagram', class: 'contacticons-filter landingpage-contacticons-exception2', url: 'publicassets/svgs/instagram.svg', link: 'https://www.instagram.com/thefreddeholm/' },
{ name: 'LinkedIn', class: 'contacticons-filter landingpage-contacticons', url: 'publicassets/svgs/linkedin.svg', link: 'https://www.linkedin.com/in/fredde-holm-2b5680232/'}
]



export const serviceData = [
  {
    name: 'Web Application Development',
    desc: 'Web design encompasses many different skills and disciplines in the production and maintenance of websites. The different areas of web design include web graphic design user interface design  authoring, including standardised code and proprietary software user experience design and search engine',
    img: "https://img.icons8.com/external-kiranshastry-lineal-color-kiranshastry/64/000000/external-web-development-coding-kiranshastry-lineal-color-kiranshastry.png"
  },
  {
    name: 'Mobile Application Development',
    desc: `We provide a range of mobile application development services
    including custom mobile development on Android platforms, building
    cross-platform apps, designing user experience and integrating
    novel mobile interfaces such as chat and voice`,
    img: "https://img.icons8.com/external-justicon-lineal-color-justicon/64/000000/external-app-development-responsive-web-design-justicon-lineal-color-justicon.png"
  },

  {
    name: 'Backend Development',
    desc: `Graphic design is a craft where professionals create visual
    content to communicate messages. By applying visual hierarchy and
    page layout techniques, designers use typography and pictures to
    meet users' specific needs and focus on the logic of displaying
    elements in interactive designs, to optimize the user experience.`,
    img: "https://img.icons8.com/external-flaticons-flat-flat-icons/64/000000/external-backend-no-code-flaticons-flat-flat-icons.png"
  },


]