/*
Inspiration: 
https://andris.gauracs.com/ 
https://diogotc.com/
https://stephanemonnot.com/

Horizontal timeline https://github.com/nickolasrm/TimelineBubbles
Vertical timelinehttps://www.youtube.com/watch?v=-rnkToU2_lw&ab_channel=AleksPopovic

TODO https://www.youtube.com/watch?v=uvB4cUi4RrI&ab_channel=edutechional 



yarn add @types/react-router-dom      eller       yarn add react-router-dom
yarn add react-typical
yarn add framer-motion
yarn add react-responsive
yarn add react-router-dom
yarn add react-responsive-carousel
*/
import React,  { forwardRef, useRef, RefObject, useState, useEffect  } from 'react';
// https://codesandbox.io/s/scroll-component-forked-6rzrq2?fontsize=14&hidenavigation=1&theme=dark&file=/src/Results.js:6-21

import ReactDOM from 'react-dom/client';
import reportWebVitals from '../reportWebVitals';
//import './App.scss'; //Denna behövs inte? 
import 'bootstrap/dist/css/bootstrap.min.css';
import { Parallax } from 'react-parallax';
import "../App.css";
import 'bootstrap/dist/css/bootstrap.min.css';

import LandingPage from '../components/landingpage/Landingpage';

import Whatiprovide from '../components/whatiprovide/whatiprovide';

import Expertise from '../components/expertise/Expertise';
import Itskills from '../components/itskills/Itskills';

import WorkCarousel from '../components/carusel/WorkCarousel';
import ContactForm from '../components/contactform/contactform';
import VerticalTimeline from '../components/timeline/VerticalTimeline';

import Navbar from "../components/navbar/navbar";

import "./home.css";

import Building from "./assets/building.jpg";
import Handshake from "./assets/handshake5.jpg";
import Church from "./assets/church2.jpg";
import Van from "./assets/van.jpg";

import Codeing from "./assets/programing.jpg";

import Footer from "../components/footer/footer";


import { BrowserRouter as Router, Route, Link } from "react-router-dom";


import routes from '../conf/routes';


const image1 =
"https://images.unsplash.com/photo-1498092651296-641e88c3b057?auto=format&fit=crop&w=1778&q=60&ixid=dW5zcGxhc2guY29tOzs7Ozs%3D";
const image2 =
"https://brightcove04pmdo-a.akamaihd.net/5104226627001/5104226627001_5297440765001_5280261645001-vs.jpg?pubId=5104226627001&videoId=5280261645001";
const image3 =
"https://img00.deviantart.net/2bd0/i/2009/276/c/9/magic_forrest_wallpaper_by_goergen.jpg";
const image4 =
"https://images.fineartamerica.com/images/artworkimages/mediumlarge/1/empire-state-building-black-and-white-square-format-john-farnan.jpg";

const inlineStyle = {
  container: {
    background: '#fff',
    left: '50%',
    top: '50%',
    position: 'absolute',
    padding: '20px',
    transform: 'translate(-50%, -50%)',
  },
} as const; //we need container and as cosnt to not get error:  https://www.kindacode.com/snippet/react-typescript-using-inline-styles-correctly/



const Homepage  = () => {
  const [loadedpage, setLoadedpage] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(()=>{
    scrolltocontactform();

    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };

  },[])

    const scrolltocontactform = () => { //processen är enkel tack vare react Hooks
      setLoadedpage(true);
    }



  return (
    
    <React.StrictMode>
    {/*<BasicTemplate />  */}

    <div>
    <title> Start | Fredde Holm </title> {/* TAB TITLE  */}
    <div id="start"/> <div id="scrolltostart">

      <LandingPage />
    </div>

    <div className='background-svg-1' >
    <div id="about"/> <div id="scrolltoabout">
      <Whatiprovide />
    </div>

    <div id="expertise"/> <div id="scrolltoexpertise">
        <Expertise />
        </div>


    <div id="scrolltoitskills">
    <Parallax bgImage={ Codeing } strength={500} className="parallaxstyle parallaxnr1">
        <div style={{ height: 500 }}>
          {/*<div style={inlineStyle.container}>HTML inside the parallax</div>*/}
        </div>
    </Parallax> 

    <div id="itskills"/>
        <Itskills />
        </div>




<div id="scrolltotimeline" >
    <Parallax bgImage={ Codeing } strength={500} className="parallaxstyle parallaxnr1">
        <div style={{ height: 500 }}>
          {/*<div style={inlineStyle.container}>HTML inside the parallax</div>*/}
        </div>
    </Parallax> 
  
    <div id="timeline"/> 
    <VerticalTimeline />
  </div>




    <div id="scrolltocontact">      

    <Parallax bgImage={screenWidth < 700 ? Church : Handshake }  strength={500} className="parallaxstyle parallaxnr2">
        <div style={{ height: 500 }}>
            {/* <div style={inlineStyle.container}>HTML inside the parallax</div>*/}
        </div>
    </Parallax> 
    {loadedpage == false && <div className='extraheigth' /> }

     <div id='contact'>
      <ContactForm />
    </div>
    

      {/*<WorkCarousel />
      <Parallax bgImage={ image4 } strength={500}>
          <div style={{ height: 500 }}>
            <div style={inlineStyle.container}>HTML inside the parallax</div>
          </div>
        </Parallax>
        <h1 style={{ textAlign: 'center' }}>| | |</h1>
      <Parallax bgImage={ image2 } blur={{ min: -1, max: 5 }}>
        <div style={{ height: 500 }}>
          <div style={inlineStyle.container}>Dinamic blur</div>
        </div>
      </Parallax>*/}


    </div>

 
    <Footer />

  </div>
  </div>
  </React.StrictMode>
  );
}

export default Homepage;