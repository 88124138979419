import React, { useContext, useState, useEffect } from "react";
import { myexpertise } from "./constants";
import { motion } from "framer-motion";
import {VerticalTimeline,VerticalTimelineElement,} from "react-vertical-timeline-component"; //https://www.npmjs.com/package/react-vertical-timeline-component
import Fab from '@material-ui/core/Fab';
import PlusIcon from '@material-ui/icons/Add';
import "./expertise.css";

const Expertise = () => {
  



  const [expandedItems, setExpandedItems] = useState(() => {
    // Initialize the state based on the myexpertise array
    return myexpertise.map((item) => ({ id: item.name, isExpanded: false }));
  });


  const handleBoxClick = (id) => {
    setExpandedItems((prevState) =>
      prevState.map((item) => {
        if (item.id === id) {
          return { ...item, isExpanded: !item.isExpanded };
        }
        return item;
      })
    );
  };



  const [displayedExpertise, setDisplayedExpertise] = useState([]);

  const [showMore, setShowMore] = useState(false);


  const updateDisplayedExpertise = () => {
    const screenWidth = window.innerWidth;
    const maxElementsToDisplay = screenWidth >= 704 ? myexpertise.length : 12;
    setDisplayedExpertise(myexpertise.slice(0, maxElementsToDisplay));
  };


useEffect(() => {
  updateDisplayedExpertise();
  window.addEventListener("resize", updateDisplayedExpertise);
  return () => {
    window.removeEventListener("resize", updateDisplayedExpertise);
  };
}, []);


const handleShowMoreClick = () => {
  setDisplayedExpertise((prevDisplayedmyexpertise) => {
    const nextIndex = prevDisplayedmyexpertise.length + 10;
    if (nextIndex >= myexpertise.length) {
      setShowMore(false);
    }
    return myexpertise.slice(0, nextIndex);
  });
};


  useEffect(() => {
    setShowMore(displayedExpertise.length < myexpertise.length);
  }, [displayedExpertise]);
  

  return (
<div id="about" >
      <div   style={{
    maxWidth: "80rem",
    margin: "0 auto",
    paddingLeft: "1rem",
    paddingRight: "1rem",
    marginTop: "0",
    paddingTop: "6rem",
    paddingBottom: "3rem",
  }}>

<h2 className="provide-title">
          My Expertise
        </h2>


        <div>
          <motion.div>
            <h4 
            style={{
    //marginTop: '3rem', // Equivalent to 'mt-12' (1 rem = 16px, so 12rem = 12 * 16px = 192px)
    //fontSize: '1.875rem', // Equivalent to 'text-3xl' (1 rem = 16px, so 3 * 16px = 48px)
    //fontWeight: '600', // Equivalent to 'font-semibold'
    //color: '#3B82F6' // Equivalent to 'text-blue-500'
  }}
  className="provide-title2"
  >
              I specialize in the following: 
            </h4>
          </motion.div>
          <motion.div style={{ display: 'flex', flexWrap: 'wrap', //TODO
          marginBottom: "70px", justifyContent: 'space-between' }} >
            {displayedExpertise.map((el, index) => (
              <div key={el.name} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', }}>
                <motion.div
                  key={el.name}
                  initial="hidden"
                  whileInView="visible"
                  variants={{
                    visible: {
                      y: 0,
                      opacity: 1,
                      transition: {
                        type: 'spring',
                      },
                    },
                    hidden: { opacity: 1, y: 80 },
                  }}
                  style={{
                    padding: '0.5rem 1rem', 
                    backgroundColor:  '#F9FAFB00' , 
                    margin: '1rem' , 
                    marginTop: '1.5rem', //TODO
                    borderRadius: '0.375rem', 
                    display: 'flex', 
                    alignItems: 'center', 
                    //cursor: 'pointer', 
                    transition: 'transform 0.2s', 
                    //boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.06)', 
                    flexDirection: 'column', //'column'  'row'
                    alignItems: 'center',
                    textAlign: 'center',
                    color: "#d43951"
                   /* border: "2px solid red",
                    color: "#d43951", */
                  }}
                  className="expertiseboxes"
                >
                  {/* Add an absolute position div to capture the click event */}
                  <div
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      //cursor: 'pointer',
                      //pointerEvents: 'auto', //'none'
                    }}
                  />
                  {/* <img alt="" src={el.link} style={{ width: '3rem' }} /> */}
                  <h4 style={{ fontSize: '1.5rem', fontWeight: 'bold', marginLeft: '0rem', /*'' */ }}>
                    {el.name}
                  </h4>

                </motion.div>
              </div>
            ))}
          </motion.div>


          {showMore && (
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center"  }}>
            <VerticalTimelineElement 
              iconOnClick={handleShowMoreClick}
              iconClassName="vertical-timeline-element-icon--button"
              icon={addButton()}
              style={{ marginLeft: "44px", marginTop: "-50px" }}
            />

            {/* <button onClick={handleShowMoreClick} className="show-more-button">
              Show More
            </button>*/}
          </div>
        )}


        </div>
      </div>
    </div>
  );
};

const addButton = () => (
  <Fab className="fabbutton" classes={{ root: 'fab-button' }} color="primary" aria-label="add">
    <PlusIcon/>
  </Fab>
);

export default Expertise;