import React, { Fragment } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { CookiesProvider } from 'react-cookie';

import routes from './conf/routes';
import { BrowserRouter} from "react-router-dom";




//const root = ReactDOM.createRoot(document.getElementById('root'));
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
//const root = ReactDOM.createRoot(document.getElementById("root")!);
root.render(
  <CookiesProvider>
    <React.StrictMode>
      <BrowserRouter > 
        <App />
      </BrowserRouter>
    </React.StrictMode>
  </CookiesProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
