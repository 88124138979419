/*
Inspiration: 
https://andris.gauracs.com/ 
https://diogotc.com/
https://stephanemonnot.com/

Horizontal timeline https://github.com/nickolasrm/TimelineBubbles
Vertical timelinehttps://www.youtube.com/watch?v=-rnkToU2_lw&ab_channel=AleksPopovic

TODO https://www.youtube.com/watch?v=uvB4cUi4RrI&ab_channel=edutechional 



yarn add @types/react-router-dom      eller       yarn add react-router-dom
yarn add react-typical
yarn add framer-motion
yarn add react-responsive
yarn add react-router-dom
yarn add react-router
yarn add react-router-hash-link
yarn add react-scroll
*/

import React, { useRef, useEffect, useState } from 'react';

import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
//import './App.scss'; //Denna behövs inte? 
import 'bootstrap/dist/css/bootstrap.min.css';
import { Parallax } from 'react-parallax';
import "./App.css";
import 'bootstrap/dist/css/bootstrap.min.css';


import WorkCarousel from './components/carusel/WorkCarousel';
import ContactForm from './components/contactform/contactform';
import VerticalTimeline from './components/timeline/VerticalTimeline';

import Navbar from "./components/navbar/navbar";

import Footer from "./components/footer/footer";

import "./universal.css"; 


import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";


import routes from './conf/routes';

import Home from "./pages/Home"
import Scrolltocontact from "./pages/scrolltocontact"

import Tweet from "./admin/Tweet"
import Login from "./admin/Login"
import TimelineDetail from './pages/Timelinedetails';
import LandingPage from './components/landingpage/Landingpage';


/*import Admin from "./admin/Login";
import Tweet from "./admin/Tweet"; */


//import TestTimelineV from './components/timeline/TestTimelineV';
//import TestResize from './components/test/testtextareaResize'; <TestResize />




  export default function App() {

    const [isLoading, setIsLoading] = React.useState(true);

    const handleLoading = () => {
    setIsLoading(false);
    }
  
    useEffect(()=>{
    window.addEventListener("load",handleLoading);
    return () => window.removeEventListener("load",handleLoading);
    },[])
  

    return ( 

      <React.StrictMode> {/* React.strictmode behövs tydligen för parallax */}          
          <Navbar />
          <Routes>
            <Route path="/" element={<Home  />} />
            <Route path="/adminpanel" element={<Tweet />} />
            <Route path="/timeline/:name" element={<TimelineDetail />} />
            <Route path="/admin" element={<Login />} />
            {/*<Route path="*" element={<Navigate to="/" path="/" replace />}/> {/* Redirect function; if not valid url, redirect to homepage  https://stackoverflow.com/questions/69868956/how-can-i-redirect-in-react-router-v6 */}
            <Route path="/contact" element={<Scrolltocontact />} />


            <Route path="*" element={<Home />}/>
          
          </Routes>
        <div className='testdiv'></div>



      </React.StrictMode>

/*
<Router>
<Fragment>
      {routes.map( ({ path, component, name}) => { //routes objectet har en path, namn och component som properties, 
        return <Route path={path} key={name} element={component} /> //här specificerar vi property namnen för våra objekt
      })}
</Fragment>
</Router>
*/


      

     
      
    
);
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
