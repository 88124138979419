


export const contactLinks = [{ name: 'Stack Overflow', url: 'https://img.icons8.com/external-tal-revivo-color-tal-revivo/40/000000/external-stack-overflow-is-a-question-and-answer-site-for-professional-logo-color-tal-revivo.png', link: 'https://stackoverflow.com/users/11788531/aakash-sharma' },
{ name: 'Instagram', url: 'https://img.icons8.com/doodle/40/000000/instagram-new--v2.png', link: 'https://www.instagram.com/aakashsh1999/' },
{ name: 'Github', url: 'https://img.icons8.com/doodle/40/000000/github--v1.png', link :'https://github.com/aakashsh1999'},
{ name: 'LinkedIn', url: 'https://img.icons8.com/doodle/40/000000/linkedin--v2.png', link: 'https://www.linkedin.com/in/aakashsh1999/'}
]



export const serviceData = [
  {
    name: 'Who I am',
    desc: 'I am an ambitious and energetic  Civil engineer who loves problem solving by finding creative solutions to complex problems. My passions include self improvement, learning new things, working out, programming, effectivizing processes and being creative in all sort of ways. I love being creative in all sort of ways and im always up for new challenges. For work I prioritize money, freedom and acquiring new skills, give me this and we will conquer the world.',
    img: "publicassets/svgs/info.svg",
    class: "provide-eachcontent-img"

  },
  {
    name: 'My strengths',
    desc: `I have a strong desire to continuously improve and I take enormous pride in all my projects hence I don’t allow myself to fail, instead doing everything I can to  deliver the highest quality possible. My self-reliance and entrepreneurial spirit combined with my ability to quickly learn new skills and master them within a short period of time allows me to easily take on any challenge and find creative solutions for complex problems. I live to solve problems so im always up for a new challange. `,
    img: "publicassets/svgs/biceps.png",
    class: "provide-eachcontent-img"

  },

  {
    name: 'Why we should work together',
    desc: `I am a highly disciplined individual with a strong drive for success and a passion for creativity and innovation. My adaptability makes me a valuable asset to any team where I'm both able to lead a project but also follow others. I am able to handle ANY task I set my mind to and deliver great results. My dedication and wide area of expertise, coupled with an obsession for quality and refusal to give up, ensure that I always deliver high-quality products.`,
    //My primary expertise is in the IT field where I excel at programing, system and process development/streamlining, testing and requirement specification. I also specialize in business development for smaller companies.
    img: "publicassets/svgs/handshake.svg",
    class: "provide-eachcontent-img-special"
  },


]